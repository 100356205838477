import React, { useEffect, useRef } from 'react'
import {
  StyleCta,
  StyledCarouselImageWrapper,
  StyledContentSection,
  StyledDescription,
  StyledImage,
  StyledOfferPromotionCarouselContainer,
  StyledOfferPromotionCarouselSection,
  StyledOfferPromotionCarouselWrapper,
  StyledTitle,
} from './styledCarouselElements'
import { StyledFlickityContentComponent, StyledFlickityImageComponent } from './styledFlickityCarousel'
import Flickity from 'react-flickity-component'
import { FlickityRefObject } from '../../types/FlickityRefObject'

export const DEFAULT_CAROUSEL_HEIGHT = {
  mobile: {
    image: 209,
    content: 320,
  },
  desktop: {
    image: 542,
    content: 542,
  },
}

// Image
export type CarouselImageProps = React.ComponentProps<typeof StyledImage> & {
  src?: string
}

export const CarouselImage: React.FC<CarouselImageProps> = ({ src, ...props }) => (
  <StyledCarouselImageWrapper item xs={12} sm={12}>
    <StyledImage src={src} {...props} />
  </StyledCarouselImageWrapper>
)

// Title
export type CarouselTitleProps = React.ComponentProps<typeof StyledTitle> & {
  //
}

export const CarouselTitle: React.FC<CarouselTitleProps> = ({ children, ...props }) => (
  <StyledTitle {...props}>{children}</StyledTitle>
)

// Description
export type CarouselDescriptionProps = React.ComponentProps<typeof StyledDescription> & {
  //
}

export const CarouselDescription: React.FC<CarouselDescriptionProps> = ({ href, children, ...props }) => (
  <StyledDescription {...props}>{children}</StyledDescription>
)

// Cta
export type CarouselCtaProps = React.ComponentProps<typeof StyleCta> & {
  //
}

export const CarouselCta: React.FC<CarouselCtaProps> = ({ href, children, ...props }) => (
  <StyleCta {...props}>{children}</StyleCta>
)

// Content section
export type ContentSectionProps = React.ComponentProps<typeof StyledContentSection> & {
  layout?: 'vertical' | 'horizontal'
}

export const ContentSection: React.FC<ContentSectionProps> = ({ layout, children, ...props }) => (
  <StyledContentSection {...props}
    container
    direction="column"
    wrap="nowrap"
    justifyContent={layout === 'vertical' ? 'center' : 'flex-start'}
    alignItems={layout === 'vertical' ? 'center' : 'flex-start'}>
    {children}
  </StyledContentSection>
)

// Carousel item container
export type OfferPromotionCarouselSectionProps = React.ComponentProps<typeof StyledOfferPromotionCarouselSection> & {
  layout?: 'vertical' | 'horizontal'
  variant?: 'offer' | 'promotion'
}

export const OfferPromotionCarouselSection: React.FC<OfferPromotionCarouselSectionProps> = ({ layout, variant, children, ...props }) => {
  if (layout === 'vertical') {
    return (
      <StyledOfferPromotionCarouselSection {...props}
        container
        direction="column"
        justifyContent="center"
        alignItems="center">
        {children}
      </StyledOfferPromotionCarouselSection>
    )
  }

  return (
    <StyledOfferPromotionCarouselSection {...props}
      container
      direction={variant === 'offer' ? 'row' : 'row-reverse'}
      justifyContent="flex-start"
      alignItems="flex-start"
      wrap="nowrap">
      {children}
    </StyledOfferPromotionCarouselSection>
  )
}

// Carousel wrapper
export type OfferPromotionCarouselWrapperProps = React.ComponentProps<typeof StyledOfferPromotionCarouselWrapper> & {
  layout?: 'vertical' | 'horizontal'
}

export const OfferPromotionCarouselWrapper: React.FC<OfferPromotionCarouselWrapperProps> = ({ layout = 'vertical', children, ...props }) => {
  if (layout === 'vertical') {
    return (
      <StyledOfferPromotionCarouselWrapper {...props}
        container
        direction="column"
        justifyContent="center"
        alignItems="center">
        <FlickityComponent layout={layout}>
          {children}
        </FlickityComponent>
      </StyledOfferPromotionCarouselWrapper>
    )
  }

  // Filter all offer items
  const offers = React.Children.toArray(children).map(item => {
    if (React.isValidElement(item)) {
      const carouselItem = React.cloneElement(item as React.ReactElement<OfferPromotionCarouselSectionProps>)

      if (carouselItem.props.variant === 'offer') {
        return carouselItem
      }
    }
  }).filter(x => x!== undefined)

  // Then filter promotion items
  const promotions = React.Children.toArray(children).map(item => {
    if (React.isValidElement(item)) {
      const carouselItem = React.cloneElement(item as React.ReactElement<OfferPromotionCarouselSectionProps>)

      if (carouselItem.props.variant === 'promotion') {
        return carouselItem
      }
    }
  }).filter(x => x!== undefined)

  return (
    <StyledOfferPromotionCarouselWrapper {...props}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start">
      {offers && offers.length > 0 && <FlickityComponent variant="offer" layout={layout}>
        {offers}
      </FlickityComponent>}
      {promotions && promotions.length > 0 && <FlickityComponent variant="promotion" layout={layout}>
        {promotions}
      </FlickityComponent>}
    </StyledOfferPromotionCarouselWrapper>
  )
}

// Flickity Component
type FlickityComponentProps = Omit<React.ComponentProps<typeof Flickity>, 'flickityRef'> & {
  variant?: 'offer' | 'promotion'
  layout: 'vertical' | 'horizontal'
}

const FlickityComponent: React.FC<FlickityComponentProps> = ({ variant, layout, children, ...props }) => {
  const flickityRefImage = useRef(null) as FlickityRefObject
  const flickityRefContent = useRef(null) as FlickityRefObject
  const flickityImageHeight = layout === 'vertical' ? DEFAULT_CAROUSEL_HEIGHT.mobile.image : DEFAULT_CAROUSEL_HEIGHT.desktop.image
  const flickityContentHeight = layout === 'vertical' ? DEFAULT_CAROUSEL_HEIGHT.mobile.content : DEFAULT_CAROUSEL_HEIGHT.desktop.content


  // Handle change other flickty content if flickty image change (and vice versa)
  useEffect(() => {
    const imageInstance = flickityRefImage.current?.flkty
    const contentInstance = flickityRefContent.current?.flkty

    if (imageInstance) {
      setTimeout(() => {
        imageInstance.select(0)
      } , 200)

      imageInstance.on('change', (index: number) => {
        if (contentInstance) contentInstance.select(index)
      })
    }

    if (contentInstance) {
      setTimeout(() => {
        contentInstance.select(0)
      } , 200)
      contentInstance.on('change', (index: number) => {
        if (imageInstance) imageInstance.select(index)
      })
    }

    setTimeout(() => {
      if (imageInstance) {
        imageInstance.select(0)
      }
    } , 200)
  }, [])

  return (
    <StyledOfferPromotionCarouselContainer $variant={variant}>
      <StyledFlickityImageComponent ref={flickityRefImage} $height={flickityImageHeight} {...props} options={{
        cellAlign: 'left',
        pageDots: false,
        wrapAround: true,
        initialIndex: 1,
        prevNextButtons: React.Children.toArray(children).length > 1,
      }}>
        {
          React.Children.toArray(children).map((section, index) => {
            const flickitySection = React.cloneElement(section as React.ReactElement<OfferPromotionCarouselSectionProps>)
            
            return (
              <React.Fragment key={index}>
                {
                  flickitySection.props.children.map(content => {
                    if (content?.props?.image === true) {
                      return content
                    }

                    return null
                  })
                }
              </React.Fragment>
            )
          })
        }
      </StyledFlickityImageComponent>
      <StyledFlickityContentComponent ref={flickityRefContent} $height={flickityContentHeight} {...props} options={{
        cellAlign: 'left',
        pageDots: React.Children.toArray(children).length > 1,
        prevNextButtons: false,
        initialIndex: 1,
        wrapAround: true,
      }}>
        {
          React.Children.toArray(children).map((section, index) => {
            const flickitySection = React.cloneElement(section as React.ReactElement<OfferPromotionCarouselSectionProps>)
            
            return (
              <React.Fragment key={index}>
                {
                  flickitySection.props.children.map(content => {
                    if (content?.props?.contentSection === true) {
                      return content
                    }
                    
                    return null
                  })
                }
              </React.Fragment>
            )
          })
        }
      </StyledFlickityContentComponent>
    </StyledOfferPromotionCarouselContainer>
  )
}